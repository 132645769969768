import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { useNavigate } from "react-router";
import { parse } from "../../qs";
import ActionBar from "../../components/ActionBar";
import { ReactComponent as ArrowBackIcon } from "bootstrap-icons/icons/arrow-left.svg";
import { ReactComponent as DoneIcon } from "bootstrap-icons/icons/check2.svg";
import { ReactComponent as LabelIcon } from "bootstrap-icons/icons/tag.svg";
import { ReactComponent as GpsFixedIcon } from "bootstrap-icons/icons/geo.svg";
import { ReactComponent as LocalShippingIcon } from "bootstrap-icons/icons/truck.svg";
import carriers, { CarrierKey } from "../../carriers";
import colors from "../../colors";
import Shortcuts from "../../Shortcuts";
import { ShipmentsContext } from "../../DataStore";
import { Carrier } from "../../carriers";
import { Helmet } from "react-helmet-async";
import styles from "./AddShipment.module.scss";
import clsx from "clsx";

const AddShipmentContainer: React.FC = ({ children }) => {
  return <div className={styles.Container}>{children}</div>;
};

interface AddShipmentFormProps {
  hasCarrier: boolean;
}
const AddShipmentForm: React.FC<AddShipmentFormProps> = ({
  hasCarrier,
  children,
}) => {
  return (
    <div
      className={clsx(styles.AddShipmentForm, hasCarrier && styles.hasCarrier)}
    >
      {children}
    </div>
  );
};

const FormField: React.FC = ({ children }) => {
  return <div className={styles.FormField}>{children}</div>;
};

interface SubmitButtonProps {
  formIsComplete: boolean;
  onClick: () => void;
}
const SubmitButton: React.FC<SubmitButtonProps> = ({
  formIsComplete,
  onClick,
  children,
}) => {
  return (
    <button
      className={clsx(
        styles.SubmitButton,
        formIsComplete && styles.formIsComplete
      )}
      title="Add shipment"
      disabled={!formIsComplete}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

function determineCarrier(hostname: string): string | null {
  if (hostname.indexOf("usps") > -1) {
    return "usps";
  } else if (hostname.indexOf("fedex") > -1) {
    return "fedex";
  } else if (hostname.indexOf("ups") > -1) {
    return "ups";
  } else if (hostname.indexOf("ontrac") > -1) {
    return "ontrac";
  }

  return null;
}

function getFirstProperty(
  collection: { [key: string]: string | string[] },
  validProperties: string[]
): string | null {
  for (let key of validProperties) {
    if (collection.hasOwnProperty(key) && collection[key] !== "") {
      return collection[key] as string;
    }
  }

  return null;
}

function determineTrackingNumber(
  carrier: string | null,
  search: string
): string | null {
  const query = parse(search);
  switch (carrier) {
    case "usps":
      return getFirstProperty(query, ["tLabels", "qtc_tLabels1"]);
    case "fedex":
      return getFirstProperty(query, ["tracknumbers", "tracknumber_list"]);
    case "ups":
      return getFirstProperty(query, ["trackNums", "tracknum"]);
    case "ontrac":
      return query.tracking_number as string;
    default:
      break;
  }

  return null;
}

function parseTrackingURL(url: string) {
  const parsedUrl = new URL(url);
  const carrier = determineCarrier(parsedUrl.hostname);
  const trackingNumber = determineTrackingNumber(carrier, parsedUrl.search);
  return { carrier, trackingNumber };
}

function sortCarriers(a: Carrier, b: Carrier): number {
  return a.displayName.localeCompare(b.displayName);
}

const AddShipment: React.FC = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [carrier, setCarrier] = useState("");
  const [isShareTarget, setIsShareTarget] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const context = useContext(ShipmentsContext);

  useEffect(() => {
    if (window.location.search !== "") {
      console.log(window.location.search);
      const query = parse(window.location.search);
      console.log(query);
      if (query.hasOwnProperty("text")) {
        const { carrier, trackingNumber } = parseTrackingURL(
          query.text as string
        );
        console.log(carrier, trackingNumber);
        if (carrier !== null && trackingNumber !== null) {
          // URL is a valid tracking URL
          setCarrier(carrier);
          setTrackingNumber(trackingNumber);
          setIsShareTarget(true);
        }
        setIsShareTarget(true);
      }
    }

    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const formIsComplete = name !== "" && carrier !== "" && trackingNumber !== "";

  const addShipment = () => {
    if (!formIsComplete) return;

    console.log("=== calling context.addShipment from component ===");
    context.addShipment({
      name,
      trackingNumber,
      carrier: carrier as CarrierKey,
    });

    if (isShareTarget) {
      navigate("/", { replace: true });
    } else {
      navigate(-1);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Add Shipment - Unitrack</title>
      </Helmet>
      <ActionBar
        left={
          <button
            title="Go back"
            onClick={() =>
              isShareTarget ? navigate("/", { replace: true }) : navigate(-1)
            }
          >
            <ArrowBackIcon width="1em" height="1em" />
          </button>
        }
        title="Add Shipment"
        right={
          <SubmitButton formIsComplete={formIsComplete} onClick={addShipment}>
            <DoneIcon width="1em" height="1em" />
          </SubmitButton>
        }
      />
      <AddShipmentContainer>
        <AddShipmentForm hasCarrier={carrier !== ""}>
          <FormField>
            <LabelIcon
              width="1em"
              height="1em"
              fill={colors.material.grey["700"]}
            />
            <input
              name="name"
              onChange={e => setName(e.target.value)}
              value={name}
              placeholder="Item Name"
              autoComplete="off"
              ref={nameInputRef}
            />
          </FormField>
          <FormField>
            <GpsFixedIcon
              width="1em"
              height="1em"
              fill={colors.material.grey["700"]}
            />
            <input
              name="trackingNumber"
              onChange={e => setTrackingNumber(e.target.value)}
              value={trackingNumber}
              placeholder="Tracking Number"
              autoComplete="off"
            />
          </FormField>
          <FormField>
            <LocalShippingIcon
              width="1em"
              height="1em"
              fill={colors.material.grey["700"]}
            />
            <select
              name="carrier"
              value={carrier}
              onChange={e => setCarrier(e.target.value)}
            >
              <option value="" disabled>
                Carrier
              </option>
              {Object.values(carriers)
                .sort(sortCarriers)
                .map(carrier => (
                  <option key={carrier.name} value={carrier.name}>
                    {carrier.displayName}
                  </option>
                ))}
            </select>
          </FormField>
        </AddShipmentForm>
      </AddShipmentContainer>
      <Shortcuts
        bindings={{
          b: () => navigate(-1),
        }}
      />
    </Fragment>
  );
};

export default AddShipment;
