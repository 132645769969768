import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ShipmentListItem from "../CompactShipmentListItem";
import DismissableAlert from "../DismissableAlert";
import { ShipmentsContext } from "../../DataStore";
import { sortShipments } from "../../utils";
import Shipment from "../../Shipment";
import ErrorBoundary from "../ErrorBoundary";
import styles from "./ShipmentList.module.scss";
import { useDensity } from "../../useSettings";
import clsx from "clsx";

const ShipmentListContainer: React.FC = ({ children }) => {
  const [density] = useDensity();
  return (
    <div
      className={clsx(
        styles.Container,
        density === "compact" && styles.ContainerCompact
      )}
    >
      {children}
    </div>
  );
};

const EmptyStateContainer: React.FC = ({ children }) => {
  return <div className={styles.EmptyState}>{children}</div>;
};

const EmptyState = () => (
  <EmptyStateContainer>
    Nothing here! Tap "+ Add Shipment" to begin tracking a shipment.
  </EmptyStateContainer>
);

const ErrorState = () => (
  <EmptyStateContainer>
    Whoops! We ran into an issue displaying your shipments. Please refresh and
    try again.
  </EmptyStateContainer>
);

const ShipmentList: React.FC = () => {
  const { shipments } = useContext(ShipmentsContext);

  return (
    <ShipmentListContainer>
      <ErrorBoundary FallbackComponent={ErrorState}>
        <DismissableAlert
          message={
            <span>
              Unitrack's synchronization functionality has been removed.{" "}
              <Link to="/sync-deprecation">Learn more</Link>
            </span>
          }
          isVisible={
            localStorage.getItem("unitrack-sync-deprecation-dismissed") === null
          }
          onDismiss={() =>
            localStorage.setItem("unitrack-sync-deprecation-dismissed", "true")
          }
        />
        {shipments !== null && Object.keys(shipments).length ? (
          sortShipments(Object.values(shipments).map(s => new Shipment(s))).map(
            s => (
              <ShipmentListItem
                key={s.id || `${s.carrier}-${s.trackingNumber}`}
                shipment={s}
              />
            )
          )
        ) : (
          <EmptyState />
        )}
      </ErrorBoundary>
    </ShipmentListContainer>
  );
};

export default ShipmentList;
