import { useCallback, useState } from "react";
import styles from "./ErrorPage.module.scss";

const ErrorPage: React.FC<{ error?: Error }> = ({ error }) => {
  const [bugReport, setBugReport] = useState("");

  const generateBugReport = useCallback(() => {
    (async () => {
      const request = indexedDB.open("KintoDB", 2);
      request.onsuccess = event => {
        const db = request.result;
        const transaction = db.transaction(db.objectStoreNames);
        const records: unknown[] = [];
        transaction
          .objectStore("records")
          .openCursor()
          .addEventListener("success", event => {
            const cursor = (event.target as IDBRequest<IDBCursorWithValue>)
              .result;
            if (cursor) {
              records.push(cursor.value);
              cursor.continue();
            } else {
              setBugReport(
                `${
                  error?.stack
                }\n-------- dump of records --------\n${JSON.stringify(
                  records,
                  null,
                  2
                )}`
              );
            }
          });
      };
    })();
  }, [error]);

  return (
    <div className={styles.container}>
      <h1>Well, this is embarrassing.</h1>
      <p>
        Unitrack has encountered a critical error, and is unable to render
        correctly. Click the button below to generate a bug report, and send it
        to me{" "}
        <a
          href="https://twitter.com/dstaley"
          target="_blank"
          rel="noopener noreferrer"
        >
          on Twitter
        </a>
        .
      </p>
      <button onClick={generateBugReport}>Generate Bug Report</button>
      {bugReport !== "" && (
        <pre>
          <code>{bugReport}</code>
        </pre>
      )}
    </div>
  );
};

export default ErrorPage;
