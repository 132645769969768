import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import ActionBar from "../../../components/ActionBar";
import { ReactComponent as ArrowBackIcon } from "bootstrap-icons/icons/arrow-left.svg";
import colors from "../../../colors";
import logo512 from "../../../assets/logo-512.png";
import { useNavigate } from "react-router";
import styles from "./About.module.scss";

const Container: React.FC = ({ children }) => {
  return <div className={styles.Container}>{children}</div>;
};

const LogoContainer: React.FC = ({ children }) => {
  return <p className={styles.LogoContainer}>{children}</p>;
};

const Title: React.FC = ({ children }) => {
  return <h2 className={styles.Title}>{children}</h2>;
};

const About: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Helmet>
        <title>About - Unitrack</title>
      </Helmet>
      <ActionBar
        left={
          <button onClick={() => navigate(-1)}>
            <ArrowBackIcon
              width="1em"
              height="1em"
              fill={colors.material.grey["600"]}
            />
          </button>
        }
        title="About"
      />
      <Container>
        <LogoContainer>
          <img src={logo512} alt="Unitrack logo" />
        </LogoContainer>
        <Title>Unitrack</Title>
        <div>
          <p>
            Unitrack is made with &lt;3 by{" "}
            <a
              href="https://dstaley.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dylan Staley
            </a>
            . It's powered by{" "}
            <a
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React
            </a>
            ,{" "}
            <a
              href="https://emotion.sh/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Emotion
            </a>
            , and{" "}
            <a
              href="https://www.kinto-storage.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kinto
            </a>
            . It does not use any tracking scripts, and only collects data
            necessary for debugging.
          </p>
        </div>
      </Container>
    </Fragment>
  );
};

export default About;
