import React, { useCallback, useContext, useState } from "react";
import { THEME_KEY, DENSITY_KEY } from "./constants";

type SupportedTheme = "light" | "dark";

function getPreferredTheme(): SupportedTheme {
  const storedTheme = localStorage.getItem(THEME_KEY);
  if (storedTheme === "system" || storedTheme === null) {
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)")
      .matches;
    return prefersDark ? "dark" : "light";
  }

  return storedTheme === "dark" ? "dark" : "light";
}

function getTheme(): string | null {
  return localStorage.getItem(THEME_KEY);
}

function getDensity(): string {
  return localStorage.getItem(DENSITY_KEY) ?? "comfortable";
}

const SettingsContext = React.createContext({
  theme: "light" as SupportedTheme,
  storedTheme: "light" as string | null,
  updateStoredTheme: (preferredTheme: string) => {},
  density: "comfortable" as string,
  updateStoredDensity: (preferredDensity: string) => {},
});

export const ThemeProvider: React.FC = ({ children }) => {
  const [storedTheme, setStoredTheme] = useState(getTheme());
  const [theme, setTheme] = useState(getPreferredTheme());

  const [density, setDensity] = useState(getDensity());

  const updateStoredTheme = useCallback((preferredTheme: string) => {
    localStorage.setItem(THEME_KEY, preferredTheme);
    setTheme(getPreferredTheme());
    setStoredTheme(preferredTheme);
  }, []);

  const updateStoredDensity = useCallback((preferredDensity: string) => {
    localStorage.setItem(DENSITY_KEY, preferredDensity);
    setDensity(preferredDensity);
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        theme,
        storedTheme,
        updateStoredTheme,
        density,
        updateStoredDensity,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useTheme = (): [
  SupportedTheme,
  string | null,
  (preferredTheme: string) => void
] => {
  const { theme, storedTheme, updateStoredTheme } = useContext(SettingsContext);
  return [theme, storedTheme, updateStoredTheme];
};

export const useDensity = (): [string, (preferredDensity: string) => void] => {
  const { density, updateStoredDensity } = useContext(SettingsContext);
  return [density, updateStoredDensity];
};
