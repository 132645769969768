interface ParsedQueryParams {
  [key: string]: string | string[];
}

interface QueryParams {
  [key: string]: string | number;
}

export function parse(str: string): ParsedQueryParams {
  const params = new URLSearchParams(str);
  const parsedParams: ParsedQueryParams = {};
  params.forEach((value, key) => {
    parsedParams[key] = value;
  });

  return parsedParams;
}

export function stringify(params: QueryParams): string {
  const p = new URLSearchParams();
  Object.keys(params).forEach(key => {
    const value = params[key];
    p.append(key, typeof value === "number" ? value.toString() : value);
  });

  return p.toString();
}
