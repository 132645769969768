import {
  datify,
  isToday,
  isTomorrow,
  isYesterday,
  midnightToday,
  month,
  dayOfWeek,
  minutesAgo,
  secondsBetween,
} from "./date-utils";
import { APIShipment, APIEvent, ShipmentFromDatastore } from "./types";
import { CarrierKey } from "./carriers";
import { stripUnecessaryHTMLElements } from "./utils";

const SIX_DAYS = 6 * 24 * 60 * 60 * 1000;
const USPS_AWAITING_ITEM = "A status update is not yet available";

export default class Shipment {
  public name: string;
  public carrier: CarrierKey;
  public trackingNumber: string;
  public trackingInfo: APIShipment | null;
  public id: string;
  public last_modified?: number;
  public timestamp: number;
  public isLoading?: boolean;

  constructor(shipment: ShipmentFromDatastore) {
    this.name = shipment.name;
    this.carrier = shipment.carrier;
    // Remove whitespace from tracking number.
    this.trackingNumber = shipment.trackingNumber.replace(/ /g, "");
    this.trackingInfo = shipment.trackingInfo;
    this.id = shipment.id;
    this.last_modified = shipment.last_modified;
    this.timestamp = shipment.timestamp;
    this.isLoading =
      typeof shipment.isLoading === "undefined" ? false : shipment.isLoading;
  }

  get key() {
    return `${this.carrier}-${this.trackingNumber}`;
  }

  get daysRemaining(): number | null {
    if (
      this.trackingInfo &&
      this.trackingInfo !== null &&
      this.trackingInfo.estimatedDeliveryDate !== null &&
      this.trackingInfo.delivered !== true &&
      !(
        this.trackingInfo.hasOwnProperty("error") ||
        this.trackingInfo.hasOwnProperty("Code")
      )
    ) {
      const estimatedDeliveryDate = datify(
        this.trackingInfo.estimatedDeliveryDate
      );
      // Force all estimated delivery dates to midnight.
      estimatedDeliveryDate.setUTCHours(0, 0, 0, 0);
      return Math.ceil(
        (estimatedDeliveryDate.getTime() - midnightToday().getTime()) / 86400400
      );
    }

    return null;
  }

  get mostRecentEvent(): APIEvent | null {
    const events = this.trackingInfo && this.trackingInfo.events;
    if (events && events.length > 0) {
      const sortedTrackingEvents = events.slice().sort((a, b) => {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      });

      return sortedTrackingEvents[sortedTrackingEvents.length - 1];
    }

    return null;
  }

  get statusLineOne(): string {
    if (this.trackingInfo === null) {
      return "...";
    }
    if (this.trackingInfo.error && this.trackingInfo.error !== "") {
      if (this.trackingInfo.error.startsWith(USPS_AWAITING_ITEM)) {
        return "A status update is not yet available on your package.";
      }
      return this.trackingInfo.error;
    }
    if (this.trackingInfo.status !== null) {
      return stripUnecessaryHTMLElements(this.trackingInfo.status);
    }

    return "Status Not Available";
  }

  get humanEta(): string {
    if (
      this.trackingInfo === null ||
      !this.trackingInfo.hasOwnProperty("estimatedDeliveryDate") ||
      this.trackingInfo.estimatedDeliveryDate === null
    ) {
      return "Delivery date unknown";
    }

    const eta = datify(this.trackingInfo.estimatedDeliveryDate);
    return `${month(eta.getMonth())} ${eta.getDate()}`;
  }

  get statusLineTwo(): string {
    if (this.trackingInfo === null) return "...";
    if (
      this.trackingInfo.hasOwnProperty("error") ||
      this.trackingInfo.hasOwnProperty("Code")
    ) {
      if (
        this.trackingInfo.error &&
        this.trackingInfo.error.startsWith(USPS_AWAITING_ITEM)
      ) {
        return "Label created, not yet in system";
      }
      return "Please check your tracking number or try again later.";
    }
    if (this.trackingInfo.delivered === true) return "Delivered!";
    if (this.trackingInfo.estimatedDeliveryDate === null)
      return "Delivery date unknown";

    const eta = datify(this.trackingInfo.estimatedDeliveryDate);

    if (eta.getTime() - new Date().getTime() > SIX_DAYS)
      return `Delivered by ${this.humanEta}`;

    if (isToday(eta)) {
      return "Delivered by Today";
    } else if (isTomorrow(eta)) {
      return "Delivered by Tomorrow";
    } else if (isYesterday(eta)) {
      return "Delivered by Yesterday";
    }

    return `Delivered by ${dayOfWeek(eta)}`;
  }

  get isStale() {
    if (
      typeof this.timestamp === "undefined" ||
      this.timestamp === null ||
      this.trackingInfo === null
    ) {
      return true;
    }
    if (this.isDelivered) return false;

    const hydratedDatetime = new Date(this.timestamp);
    return hydratedDatetime < minutesAgo(15);
  }

  get lastRefresh() {
    if (this.timestamp === null || typeof this.timestamp === "undefined")
      return null;

    const hydratedDatetime = new Date(this.timestamp);
    const secondsPast = secondsBetween(new Date(), hydratedDatetime);

    if (secondsPast < 60) {
      const seconds = Math.floor(secondsPast);
      return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
    } else if (secondsPast < 3600) {
      const minutes = Math.floor(secondsPast / 60);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (secondsPast <= 86400) {
      const hours = Math.floor(secondsPast / 3600);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    }

    const dateString = `on ${hydratedDatetime.toLocaleDateString()}`;
    return `${dateString} at ${hydratedDatetime.toLocaleTimeString()}`;
  }

  get isDelivered() {
    return this.trackingInfo !== null && this.trackingInfo.delivered === true;
  }

  get sortKey(): number {
    if (this.isDelivered)
      return datify(this.mostRecentEvent!.timestamp).getTime() / 2;
    if (
      this.trackingInfo !== null &&
      this.trackingInfo.estimatedDeliveryDate !== null
    )
      return datify(this.trackingInfo.estimatedDeliveryDate).getTime() / 1;
    return -1;
  }

  get status(): string | null {
    return this.trackingInfo !== null ? this.trackingInfo.status : null;
  }

  get hasError(): boolean {
    return (
      this.trackingInfo !== null &&
      (this.trackingInfo.hasOwnProperty("error") ||
        this.trackingInfo.hasOwnProperty("Code"))
    );
  }
}
