import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { register } from "./registerServiceWorker";
import { ThemeProvider } from "./useSettings";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
register();

// reportWebVitals();
