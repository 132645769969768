export type CarrierKey =
  | "fedex"
  | "iparcel"
  | "ontrac"
  | "ups"
  | "usps"
  | "dhl";

export interface Carrier {
  name: CarrierKey;
  displayName: string;
  backgroundColor: string;
  foregroundColor: string;
  trackingLink: (s: string) => string;
}

export const carrierKeys: { [key: string]: CarrierKey } = {
  FEDEX: "fedex",
  IPARCEL: "iparcel",
  ONTRAC: "ontrac",
  UPS: "ups",
  USPS: "usps",
};

const carriers: { [C in CarrierKey]: Carrier } = {
  fedex: {
    name: "fedex",
    displayName: "FedEx",
    backgroundColor: "#4B1388",
    foregroundColor: "white",
    trackingLink: trackingNumber =>
      `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`,
  },
  iparcel: {
    name: "iparcel",
    displayName: "UPS i-parcel",
    backgroundColor: "#644117",
    foregroundColor: "#ffb500",
    trackingLink: trackingNumber =>
      `https://tracking.i-parcel.com/Home/Index?trackingnumber=${trackingNumber}`,
  },
  ontrac: {
    name: "ontrac",
    displayName: "OnTrac",
    backgroundColor: "#f9d248",
    foregroundColor: "#012a68",
    trackingLink: trackingNumber =>
      `http://www.ontrac.com/trackingresults.asp?tracking_number=${trackingNumber}&tryTrackingGo=Track`,
  },
  ups: {
    name: "ups",
    displayName: "UPS",
    backgroundColor: "#351c15",
    foregroundColor: "#ffb500",
    trackingLink: trackingNumber =>
      `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`,
  },
  usps: {
    name: "usps",
    displayName: "USPS",
    backgroundColor: "#333366",
    foregroundColor: "#F7F7F7",
    trackingLink: trackingNumber =>
      `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`,
  },
  dhl: {
    name: "dhl",
    displayName: "DHL",
    backgroundColor: "#ffcc00",
    foregroundColor: "#cc0000",
    trackingLink: trackingNumber =>
      `https://www.logistics.dhl/us-en/home/tracking.html?tracking-id=${trackingNumber}`,
  },
};

export default carriers;
