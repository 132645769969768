import React, { useContext, useRef, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import ShipmentDetail from "../components/ShipmentDetail";
import ActionBar from "../components/ActionBar";
import ConfirmDialog from "../components/ConfirmDialog";
import { ReactComponent as ArrowBackIcon } from "bootstrap-icons/icons/arrow-left.svg";
import { ReactComponent as DeleteForeverIcon } from "bootstrap-icons/icons/trash.svg";
import colors from "../colors";
import Shortcuts from "../Shortcuts";
import { ShipmentsContext } from "../DataStore";
import { useNavigate, useParams } from "react-router";
import Shipment from "../Shipment";
import A11yDialog from "a11y-dialog";

const ShipmentDetailRoute: React.FC = () => {
  const navigate = useNavigate();
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const context = useContext(ShipmentsContext);
  const confirmDialog = useRef<A11yDialog>();
  if (!shipmentId || !context.shipments.hasOwnProperty(shipmentId)) return null;
  const shipment = context.shipments[shipmentId]!;
  const decoratedShipment = new Shipment(shipment);
  const { name } = decoratedShipment;

  const BackButton = (
    <button title="Go back" onClick={() => navigate(-1)}>
      <ArrowBackIcon
        width="1em"
        height="1em"
        fill={colors.material.grey["600"]}
      />
    </button>
  );

  const deleteShipment = () => {
    confirmDialog.current?.hide();
    context.deleteShipment(shipment);
    navigate(-1);
  };

  const DeleteButton = (
    <button
      title="Delete shipment"
      onClick={() => confirmDialog.current?.show()}
    >
      <DeleteForeverIcon
        width="1em"
        height="1em"
        fill={colors.material.grey["600"]}
      />
    </button>
  );

  return (
    <Fragment>
      <Helmet>
        <title>{`${name} - Unitrack`}</title>
      </Helmet>
      <ActionBar left={BackButton} right={DeleteButton} />
      <ShipmentDetail shipmentId={shipmentId} />
      <ConfirmDialog
        dialogRef={d => (confirmDialog.current = d)}
        title={`Delete ${shipment.name}?`}
        onCancel={() => confirmDialog.current?.hide()}
        onConfirm={deleteShipment}
      />
      <Shortcuts
        bindings={{
          b: () => navigate(-1),
          d: () => {
            deleteShipment();
          },
        }}
      />
    </Fragment>
  );
};

export default ShipmentDetailRoute;
