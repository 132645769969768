import clsx from "clsx";
import Button from "../Button";
import { A11yDialog } from "react-a11y-dialog";
import elevation from "../../styles/elevation.module.css";
import styles from "./ConfirmDialog.module.css";

interface Props {
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  dialogRef: React.ComponentProps<typeof A11yDialog>["dialogRef"];
}

const ConfirmDialog: React.FC<Props> = ({
  title,
  onCancel,
  onConfirm,
  dialogRef,
}) => {
  return (
    <A11yDialog
      id="confirm-dialog"
      title={title}
      dialogRef={dialogRef}
      classNames={{
        container: styles.container,
        overlay: styles.overlay,
        dialog: clsx(styles.content, elevation.z4),
        title: styles.title,
      }}
      dialogRoot="#appRoot"
      closeButtonPosition="none"
    >
      <p>This cannot be undone.</p>
      <div className={styles.buttonRow}>
        <Button variant="primary" outline onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Delete
        </Button>
      </div>
    </A11yDialog>
  );
};

export default ConfirmDialog;
