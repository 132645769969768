import React from "react";
import { stripUnecessaryHTMLElements } from "../../utils";
import Shipment from "../../Shipment";
import { APIEvent } from "../../types";
import styles from "./StatusTable.module.css";

function sortTrackingHistory(a: APIEvent, b: APIEvent) {
  return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
}

const Container: React.FC = ({ children }) => {
  return <div className={styles.Container}>{children}</div>;
};

const TrackingHistoryListItem: React.FC = ({ children }) => {
  return <div className={styles.TrackingHistoryListItem}>{children}</div>;
};

const Error: React.FC = ({ children }) => {
  return <p className={styles.Error}>{children}</p>;
};

function formatDatetime(datetime: Date): string {
  const date = datetime.toLocaleDateString("en-US", {
    timeZone: "UTC",
    month: "short",
    day: "numeric",
  });
  const time = datetime.toLocaleTimeString("en-US", {
    timeZone: "UTC",
    hour: "numeric",
    minute: "numeric",
  });

  return `${date}, ${time}`;
}

interface Props {
  shipment: Shipment;
}

const StatusTable: React.FC<Props> = ({ shipment }) => {
  if (shipment.trackingInfo && shipment.trackingInfo.hasOwnProperty("error")) {
    return (
      <Error>
        {stripUnecessaryHTMLElements(shipment.trackingInfo!.error!)}
      </Error>
    );
  }

  const trackingHistory =
    shipment.trackingInfo &&
    shipment.trackingInfo.events &&
    shipment.trackingInfo.events.length > 0
      ? shipment.trackingInfo.events
      : [];

  return (
    <Container>
      {trackingHistory.sort(sortTrackingHistory).map((status, i) => {
        const statusDatetime = new Date(status.timestamp);
        return (
          <TrackingHistoryListItem
            key={`${i}-${shipment.trackingNumber}-${status.timestamp}`}
          >
            <h2>{status.description}</h2>
            <p>
              {[
                status["location"] !== null ? status.location : "",
                formatDatetime(statusDatetime),
              ]
                .filter(Boolean)
                .join(" ∙ ")}
            </p>
          </TrackingHistoryListItem>
        );
      })}
    </Container>
  );
};

export default StatusTable;
