import clsx from "clsx";
import styles from "./Spinner.module.css";

const Spinner: React.FC<{ size?: number }> = ({ size = 48 }) => {
  return (
    <div
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={1}
      className={clsx(styles.Spinner, styles.indeterminate)}
      aria-label=""
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <div className={styles.container}>
        <div className={styles.spinnerLayer}>
          <div className={clsx(styles.circleClipper, styles.circleLeft)}>
            <svg className={styles.circleGraphic} viewBox="0 0 48 48">
              <circle
                cx="24"
                cy="24"
                r="18"
                strokeDasharray="113.0973336"
                strokeDashoffset="56.5486668"
                strokeWidth="4"
              ></circle>
            </svg>
          </div>
          <div className={styles.gapPatch}>
            <svg className={styles.circleGraphic} viewBox="0 0 48 48">
              <circle
                cx="24"
                cy="24"
                r="18"
                strokeDasharray="113.0973336"
                strokeDashoffset="56.5486668"
                strokeWidth="3.2"
              ></circle>
            </svg>
          </div>
          <div className={clsx(styles.circleClipper, styles.circleRight)}>
            <svg className={styles.circleGraphic} viewBox="0 0 48 48">
              <circle
                cx="24"
                cy="24"
                r="18"
                strokeDasharray="113.0973336"
                strokeDashoffset="56.5486668"
                strokeWidth="4"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
