import React from "react";
import styles from "./Select.module.css";

interface Props {
  value?: string | number | readonly string[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: React.FC<Props> = ({ value, onChange, children }) => {
  return (
    <select className={styles.control} value={value} onChange={onChange}>
      {children}
    </select>
  );
};

export default Select;
