import React from "react";

interface Props {
  FallbackComponent: React.ComponentType<{ error?: Error }>;
}

interface State {
  error?: Error;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: unknown) {
    console.error(error);
  }

  render() {
    const { FallbackComponent } = this.props;
    if (this.state.error) {
      return <FallbackComponent error={this.state.error} />;
    }

    return this.props.children;
  }
}
