import React, { useEffect, useCallback, useRef, useState } from "react";
import type Kinto from "kinto";
import type { Collection } from "kinto";
import { CarrierKey } from "./carriers";
import { APIShipment, ShipmentInfo, ShipmentFromDatastore } from "./types";
import { minutesAgo } from "./date-utils";

export interface PartialShipment {
  name: string;
  carrier: CarrierKey;
  trackingNumber: string;
  trackingInfo: null;
}

const ENDPOINT = "https://track.getunitrack.com";

export async function getTrackingStatus(
  trackingNumber: string,
  carrier: CarrierKey
): Promise<APIShipment | null> {
  const url = [ENDPOINT, carrier, trackingNumber].join("/");
  const response = await fetch(url);
  const trackingInfo = await response.json();
  return trackingInfo;
}

function isValidShipment(obj: Object): boolean {
  return (
    obj.hasOwnProperty("trackingNumber") &&
    obj.hasOwnProperty("name") &&
    obj.hasOwnProperty("carrier") &&
    obj.hasOwnProperty("trackingInfo")
  );
}

// this.trackingInfo !== null && this.trackingInfo.delivered === true

function isStale(shipment: ShipmentFromDatastore): boolean {
  if (
    typeof shipment.timestamp === "undefined" ||
    shipment.timestamp === null ||
    shipment.trackingInfo === null
  ) {
    return true;
  }
  if (
    shipment.trackingInfo !== null &&
    shipment.trackingInfo.delivered === true
  )
    return false;

  const hydratedDatetime = new Date(shipment.timestamp);
  return hydratedDatetime < minutesAgo(15);
}

interface ShipmentsContextType {
  shipments: { [key: string]: ShipmentFromDatastore };
  isLoading: boolean;
  addShipment: (s: ShipmentInfo) => Promise<void>;
  deleteShipment: (s: ShipmentFromDatastore) => Promise<void>;
  requestShipmentUpdate: (forceUpdate: boolean) => Promise<void>;
  getBucketPermissions: () => Promise<{
    [key: string]: string[] | undefined;
  } | null>;
}

export const ShipmentsContext = React.createContext<ShipmentsContextType>({
  shipments: {} as { [key: string]: ShipmentFromDatastore },
  isLoading: false,
  addShipment: (s: ShipmentInfo) => Promise.resolve(),
  // updateShipment: (s: ShipmentFromDatastore) => Promise.resolve(),
  deleteShipment: (s: ShipmentFromDatastore) => Promise.resolve(),
  requestShipmentUpdate: (forceUpdate: boolean) => Promise.resolve(),
  getBucketPermissions: () => Promise.resolve(null),
});

type ShipmentsById = { [key: string]: ShipmentFromDatastore };

const DatastoreHooks: React.FC = ({ children }) => {
  const store = useRef<Kinto<any> | null>(null);
  const shipmentStore = useRef<Collection<ShipmentFromDatastore> | null>(null);
  const [shipments, setShipments] = useState<ShipmentsById>({});
  const [isLoading, setIsLoading] = useState(false);
  const isUpdating = useRef(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const updateStateFromStore = useCallback(() => {
    console.log("callback called: updateStateFromStore");
    if (!isInitialized || shipmentStore.current === null) return;
    return shipmentStore.current.list().then(records => {
      setShipments(
        records.data.filter(isValidShipment).reduce((a, b) => {
          a[b.id] = b;
          return a;
        }, {} as ShipmentsById)
      );
    });
  }, [isInitialized]);

  const requestShipmentUpdate = useCallback(
    (forceUpdate: boolean = false) => {
      console.log("callback called: requestShipmentUpdate");
      if (
        !isInitialized ||
        shipmentStore.current === null ||
        isUpdating.current
      ) {
        return Promise.resolve(false);
      }

      async function r() {
        isUpdating.current = true;
        const shipments = (await shipmentStore.current!.list()).data.filter(
          isValidShipment
        );
        const shipmentsNeedingUpdate = forceUpdate
          ? shipments
          : shipments.filter(s => isStale(s));
        if (shipmentsNeedingUpdate.length > 0) {
          try {
            const loadingShipments = shipments.reduce((a, b) => {
              a[b.id] = b;
              return a;
            }, {} as ShipmentsById);
            shipmentsNeedingUpdate.forEach(s => {
              loadingShipments[s.id].isLoading = true;
            });
            setShipments(loadingShipments);
            console.log("Requesting updates");
            let updatedShipments = await Promise.all(
              shipmentsNeedingUpdate.map(async shipment => {
                console.log("getting tracking");
                const trackingInfo = await getTrackingStatus(
                  shipment.trackingNumber,
                  shipment.carrier
                );
                console.log("got tracking");
                return {
                  id: shipment.id,
                  name: shipment.name,
                  carrier: shipment.carrier,
                  trackingNumber: shipment.trackingNumber,
                  trackingInfo,
                };
              })
            );
            updatedShipments = updatedShipments.filter(isValidShipment);
            await shipmentStore.current!.execute(
              txn => {
                updatedShipments.forEach(s => {
                  txn.update({ ...s, timestamp: new Date().getTime() });
                });
              },
              { preloadIds: updatedShipments.map(s => s.id) }
            );
          } catch (err) {
            console.error(err);
          }

          await updateStateFromStore();
          setIsLoading(false);
          isUpdating.current = false;
          return true;
        }

        setIsLoading(false);
        isUpdating.current = false;
        return false;
      }
      return r();
    },
    [updateStateFromStore, isInitialized]
  );

  const deleteShipment = useCallback(
    (shipment: ShipmentFromDatastore) => {
      async function d() {
        if (!isInitialized || shipmentStore.current === null) return;
        await shipmentStore.current.delete(shipment.id);
        await updateStateFromStore();
      }

      return d();
    },
    [updateStateFromStore, isInitialized]
  );

  const getBucketPermissions = useCallback(() => {
    async function g() {
      if (!isInitialized || store.current === null) return null;
      return null;
    }
    return g();
  }, [isInitialized]);

  const addShipment = useCallback(
    (s: ShipmentInfo) => {
      console.log("callback called: addShipment");
      async function a() {
        if (!isInitialized || shipmentStore.current === null) return;
        await shipmentStore.current.create({
          ...s,
          trackingInfo: null,
          timestamp: new Date().getTime(),
        });
        await updateStateFromStore();
        await requestShipmentUpdate();
      }
      return a();
    },
    [updateStateFromStore, requestShipmentUpdate, isInitialized]
  );

  const userRequestedShipmentUpdate = useCallback(
    (forceUpdate: boolean) => {
      async function u() {
        setIsLoading(true);
        await requestShipmentUpdate(forceUpdate);
        setIsLoading(false);
      }
      return u();
    },
    [requestShipmentUpdate]
  );

  useEffect(() => {
    import("kinto").then(result => {
      store.current = new result.default();
      shipmentStore.current =
        store.current.collection<ShipmentFromDatastore>("shipments");
      setIsInitialized(true);
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (isInitialized) {
        await updateStateFromStore();
        setIsLoading(true);
        await requestShipmentUpdate();
        await updateStateFromStore();
        setIsLoading(false);
      }
    })();
  }, [isInitialized, updateStateFromStore, requestShipmentUpdate]);

  return (
    <ShipmentsContext.Provider
      value={{
        shipments,
        isLoading,
        addShipment,
        deleteShipment,
        requestShipmentUpdate: userRequestedShipmentUpdate,
        getBucketPermissions,
      }}
    >
      {children}
    </ShipmentsContext.Provider>
  );
};

export default DatastoreHooks;
