import React, { useContext } from "react";
import StatusTable from "../StatusTable";
import TrackingNumber from "../TrackingNumber";
import CarrierTrackingLink from "../CarrierTrackingLink";
import carriers from "../../carriers";
import { ShipmentsContext } from "../../DataStore";
import Shipment from "../../Shipment";
import { datify, month } from "../../date-utils";
import { ShipmentFromDatastore } from "../../types";
import styles from "./ShipmentDetail.module.scss";

const ShipmentDetailContainer: React.FC = ({ children }) => {
  return <div className={styles.Container}>{children}</div>;
};

const LastRefresh: React.FC = ({ children }) => {
  return <p className={styles.LastRefresh}>{children}</p>;
};

const ShipmentDetailHeader: React.FC = ({ children }) => {
  return <div className={styles.Header}>{children}</div>;
};

interface HeaderProps {
  shipment: ShipmentFromDatastore;
  decoratedShipment: Shipment;
}
const Header: React.FC<HeaderProps> = ({ shipment, decoratedShipment }) => {
  const deliveryDate =
    shipment.trackingInfo &&
    shipment.trackingInfo.delivered &&
    decoratedShipment.mostRecentEvent
      ? datify(decoratedShipment.mostRecentEvent.timestamp)
      : null;

  return (
    <ShipmentDetailHeader>
      <h2>{decoratedShipment.name}</h2>
      {shipment.trackingInfo ? (
        deliveryDate ? (
          <p>{`Delivered on ${month(
            deliveryDate.getMonth()
          )} ${deliveryDate.getDate()}`}</p>
        ) : shipment.trackingInfo.estimatedDeliveryDate ? (
          <p>{`Delivered by ${decoratedShipment.humanEta}`}</p>
        ) : (
          <p>Delivery date unknown</p>
        )
      ) : null}
    </ShipmentDetailHeader>
  );
};

const ShipmentDetail: React.FC<{ shipmentId: string }> = ({ shipmentId }) => {
  const context = useContext(ShipmentsContext);
  if (!context.shipments.hasOwnProperty(shipmentId)) return null;
  const shipment = context.shipments[shipmentId]!;
  const decoratedShipment = new Shipment(shipment);
  const { carrier, trackingNumber, lastRefresh } = decoratedShipment;

  return (
    <ShipmentDetailContainer>
      <Header shipment={shipment} decoratedShipment={decoratedShipment} />
      <CarrierTrackingLink carrier={carrier} trackingNumber={trackingNumber}>
        <TrackingNumber carrier={carrier}>
          <h3>{trackingNumber}</h3>
          <span>{carriers[carrier].displayName}</span>
        </TrackingNumber>
      </CarrierTrackingLink>
      <StatusTable shipment={decoratedShipment} />
      {lastRefresh !== null ? (
        <LastRefresh>Last refreshed {lastRefresh}</LastRefresh>
      ) : null}
    </ShipmentDetailContainer>
  );
};

export default ShipmentDetail;
