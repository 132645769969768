export function dayOfWeek(datetime: Date): string {
  return [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][datetime.getDay()];
}

export function month(month: number): string {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][month];
}

export function midnightToday(): Date {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return now;
}

export class TimezonelessDate extends Date {
  getDate() {
    return this.getUTCDate();
  }

  getMonth() {
    return this.getUTCMonth();
  }

  getDay() {
    return this.getUTCDay();
  }

  toLocaleDateString() {
    return super.toLocaleDateString("en-US", { timeZone: "UTC" });
  }

  toLocaleTimeString() {
    return super.toLocaleTimeString("en-US", { timeZone: "UTC" });
  }
}

export function isYesterday(d: Date): boolean {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return d.toLocaleDateString() === yesterday.toLocaleDateString();
}

export function isToday(d: Date): boolean {
  return d.toLocaleDateString() === new Date().toLocaleDateString();
}

export function isTomorrow(d: Date): boolean {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return d.toLocaleDateString() === tomorrow.toLocaleDateString();
}

export function datify(possibleDate: string | Date): Date {
  if (typeof possibleDate === "string") {
    return new TimezonelessDate(
      possibleDate[possibleDate.length - 1] === "Z"
        ? possibleDate
        : `${possibleDate}Z`
    );
  }

  return possibleDate;
}

export function minutesAgo(minutes: number): Date {
  const now = new Date();
  now.setMinutes(now.getMinutes() - minutes);
  return now;
}

export function secondsBetween(a: Date, b: Date): number {
  return (a.getTime() - b.getTime()) / 1000;
}
