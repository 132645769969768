import clsx from "clsx";
import styles from "./Button.module.scss";

type ButtonVariant =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark";

interface Props {
  variant?: ButtonVariant;
  outline?: boolean;
  size?: "small" | "large";
}

const Button: React.FC<
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
> = props => {
  const { variant = "primary", outline, size, children, ...rest } = props;
  return (
    <button
      {...rest}
      className={clsx(
        styles.button,
        !outline && {
          [styles.buttonPrimary]: variant === "primary",
          [styles.buttonSecondary]: variant === "secondary",
          [styles.buttonSuccess]: variant === "success",
          [styles.buttonDanger]: variant === "danger",
          [styles.buttonWarning]: variant === "warning",
          [styles.buttonInfo]: variant === "info",
          [styles.buttonLight]: variant === "light",
          [styles.buttonDark]: variant === "dark",
        },
        outline && {
          [styles.buttonOutlinePrimary]: variant === "primary",
          [styles.buttonOutlineSecondary]: variant === "secondary",
          [styles.buttonOutlineSuccess]: variant === "success",
          [styles.buttonOutlineDanger]: variant === "danger",
          [styles.buttonOutlineWarning]: variant === "warning",
          [styles.buttonOutlineInfo]: variant === "info",
          [styles.buttonOutlineLight]: variant === "light",
          [styles.buttonOutlineDark]: variant === "dark",
        },
        size === "small" && styles.buttonSmall,
        size === "large" && styles.buttonLarge
      )}
    >
      {children}
    </button>
  );
};

export default Button;
