import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { ReactComponent as AddIcon } from "bootstrap-icons/icons/plus.svg";
import { useNavigate } from "react-router-dom";
import styles from "./AddShipmentButton.module.scss";
import elevation from "../../styles/elevation.module.css";

const FloatingActionButton: React.FC<{ onClick: () => void }> = ({
  onClick,
  children,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const onMouseEnterLeave = useCallback(() => {
    setIsHovered(!isHovered);
  }, [isHovered]);

  const onFocusBlur = useCallback(() => {
    setIsFocused(!isFocused);
  }, [isFocused]);

  const onMouseDownUp = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  return (
    <button
      onClick={onClick}
      onMouseEnter={onMouseEnterLeave}
      onMouseLeave={onMouseEnterLeave}
      onFocus={onFocusBlur}
      onBlur={onFocusBlur}
      onMouseDown={onMouseDownUp}
      onMouseUp={onMouseDownUp}
      className={clsx(styles.FloatingActionButton, elevation.z6, {
        [elevation.z8]: isHovered || isFocused,
        [elevation.z12]: isActive,
      })}
    >
      {children}
    </button>
  );
};

const Text: React.FC = ({ children }) => {
  return <span className={styles.Text}>{children}</span>;
};

const AddShipmentButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <FloatingActionButton onClick={() => navigate("/add")}>
      <AddIcon width="1em" height="1em" />
      <Text>Add shipment</Text>
    </FloatingActionButton>
  );
};

export default AddShipmentButton;
