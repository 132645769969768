import React, { useEffect, useState, ReactElement } from "react";
import clsx from "clsx";
import ProgressBar from "../ProgressBar";
import styles from "./ActionBar.module.scss";
import elevation from "../../styles/elevation.module.css";

const Container: React.FC<{ scrolled: boolean }> = ({ scrolled, children }) => {
  return (
    <header className={clsx(styles.Container, scrolled && elevation.z2)}>
      {children}
    </header>
  );
};

const ActionBarRow: React.FC = ({ children }) => {
  return <div className={styles.ActionBarRow}>{children}</div>;
};

const ActionBarStart: React.FC = ({ children }) => {
  return (
    <span className={clsx(styles.ActionBarSection, styles.ActionBarStart)}>
      {children}
    </span>
  );
};
const ActionBarEnd: React.FC = ({ children }) => {
  return (
    <span className={clsx(styles.ActionBarSection, styles.ActionBarEnd)}>
      {children}
    </span>
  );
};

const ActionBarTitle: React.FC = ({ children }) => {
  return <span className={styles.ActionBarTitle}>{children}</span>;
};

interface Props {
  left?: ReactElement;
  title?: string;
  right?: ReactElement;
  isLoading?: boolean;
}
const ActionBar: React.FC<Props> = props => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrolled && window.scrollY > 0) {
        setScrolled(true);
      } else if (scrolled && window.scrollY === 0) {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Container scrolled={scrolled}>
      <ActionBarRow>
        <ActionBarStart>
          {props.left ? props.left : null}
          {props.title ? <ActionBarTitle>{props.title}</ActionBarTitle> : null}
        </ActionBarStart>
        <ActionBarEnd>{props.right ? props.right : null}</ActionBarEnd>
      </ActionBarRow>
      <ProgressBar isLoading={props.isLoading} />
    </Container>
  );
};

export default ActionBar;
