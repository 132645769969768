import React from "react";
import { ReactComponent as USPSLogo } from "./usps.svg";
import { ReactComponent as OnTracLogo } from "./ontrac.svg";
import { ReactComponent as UPSLogo } from "./ups.svg";
import { ReactComponent as FedexLogo } from "./fedex.svg";
import { ReactComponent as DHLLogo } from "./dhl.svg";
import { CarrierKey } from "../carriers";

const CarrierLogo = ({ carrier }: { carrier: CarrierKey }) => {
  switch (carrier) {
    case "usps":
      return <USPSLogo />;
    case "ups":
      return <UPSLogo />;
    case "iparcel":
      return <UPSLogo />;
    case "fedex":
      return <FedexLogo />;
    case "ontrac":
      return <OnTracLogo />;
    case "dhl":
      return <DHLLogo />;
    default:
      return null;
  }
};

export default CarrierLogo;
