import React, { useCallback, useContext, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as SettingsIcon } from "bootstrap-icons/icons/gear.svg";
import { ReactComponent as RefreshIcon } from "bootstrap-icons/icons/arrow-repeat.svg";
import { Helmet } from "react-helmet-async";
import ActionBar from "../components/ActionBar";
import colors from "../colors";
import AddShipmentButton from "../components/AddShipmentButton";
import ShipmentList from "../components/ShipmentList";
// import { SettingsIcon } from "../Icons";
import Shortcuts from "../Shortcuts";
import { ShipmentsContext } from "../DataStore";

const useVisibility = (onChange: () => void) => {
  useEffect(() => {
    document.addEventListener("visibilitychange", onChange);
    document.addEventListener("resume", onChange);

    return () => {
      document.removeEventListener("visibilitychange", onChange);
      document.removeEventListener("resume", onChange);
    };
  });
};

const ShipmentListRoute: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, requestShipmentUpdate } = useContext(ShipmentsContext);

  const updateShipments = useCallback(() => {
    if (!document.hidden) {
      requestShipmentUpdate(false);
    }
  }, [requestShipmentUpdate]);

  useVisibility(updateShipments);

  return (
    <Fragment>
      <Helmet>
        <title>Shipments - Unitrack</title>
      </Helmet>
      <ActionBar
        title="Shipments"
        right={
          <>
            <button title="Refresh" onClick={() => requestShipmentUpdate(true)}>
              <RefreshIcon
                width="1em"
                height="1em"
                fill={colors.material.grey["600"]}
              />
            </button>
            <Link to="/settings">
              <SettingsIcon title="Settings" width="1em" height="1em" />
            </Link>
          </>
        }
        isLoading={isLoading}
      />
      <ShipmentList />
      <AddShipmentButton />
      <Shortcuts
        bindings={{
          a: () => {
            navigate("/add");
          },
          r: () => requestShipmentUpdate(true),
        }}
      />
    </Fragment>
  );
};

export default ShipmentListRoute;
