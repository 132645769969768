import clsx from "clsx";
import React from "react";
import styles from "./Prose.module.css";

interface Props {
  additionalClasses?: string | string[];
}

const Prose: React.FC<Props> = ({ additionalClasses, children }) => {
  return (
    <div className={clsx(styles.prose, additionalClasses)}>{children}</div>
  );
};

export default Prose;
