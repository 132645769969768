import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ErrorPage from "./components/ErrorPage";
import ShipmentList from "./routes/ShipmentList";
import ShipmentDetail from "./routes/ShipmentDetail";
import AddShipment from "./routes/AddShipment";
import Settings from "./routes/settings/Settings";
import About from "./routes/settings/About";
import ReleaseNotes from "./routes/ReleaseNotes";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import SyncDeprecation from "./routes/SyncDeprecation";
import Datastore from "./DataStore";
import ScrollTop from "./ScrollTop";
import { useTheme } from "./useSettings";
import ErrorBoundary from "./components/ErrorBoundary";
import styles from "./App.module.scss";
import clsx from "clsx";

const Wrapper: React.FC = ({ children }) => {
  const [theme] = useTheme();
  return (
    <div
      id="appRoot"
      className={clsx(styles.App, theme === "dark" && styles.darkTheme)}
    >
      {children}
    </div>
  );
};

const AppContainer: React.FC = ({ children }) => {
  return <div className={styles.Container}>{children}</div>;
};

const App = () => {
  const [theme] = useTheme();

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <HelmetProvider>
        <Helmet>
          <meta
            name="theme-color"
            content={theme === "dark" ? "#121212" : "#ffffff"}
          />
        </Helmet>
        <Router>
          <ScrollTop>
            <Wrapper>
              <Datastore>
                <AppContainer>
                  <Routes>
                    <Route path="/release-notes" element={<ReleaseNotes />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/settings/about" element={<About />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route
                      path="/shipment/:shipmentId"
                      element={<ShipmentDetail />}
                    />
                    <Route path="/add" element={<AddShipment />} />
                    <Route path="/" element={<ShipmentList />} />
                    <Route
                      path="/sync-deprecation"
                      element={<SyncDeprecation />}
                    />
                  </Routes>
                </AppContainer>
              </Datastore>
            </Wrapper>
          </ScrollTop>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  );
};

export default App;
