import clsx from "clsx";
import styles from "./ProgressBar.module.scss";

const ProgressBar = ({ isLoading = false }) => (
  <div
    className={clsx(styles.Progress, isLoading && styles.isLoading)}
    role="progressbar"
  >
    <div className={styles.Buffer} />
    <div className={clsx(styles.Bar, styles.Primary)}>
      <div className={clsx(styles.Inner, styles.PrimaryInner)} />
    </div>
    <div className={clsx(styles.Bar, styles.Secondary)}>
      <div className={clsx(styles.Inner, styles.SecondaryInner)} />
    </div>
  </div>
);

export default ProgressBar;
