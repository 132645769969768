const colors = {
  google: {
    blue: "#4285F4",
  },
  material: {
    blue: {
      "400": "#42A5F5",
      "800": "#1565C0",
      "900": "#0D47A1",
      A200: "#448AFF",
      A700: "#2962FF",
    },
    grey: {
      "50": "#FAFAFA",
      "100": "#F5F5F5",
      "200": "#EEEEEE",
      "300": "#E0E0E0",
      "400": "#BDBDBD",
      "500": "#9E9E9E",
      "600": "#757575",
      "700": "#616161",
      "800": "#424242",
      "900": "#212121",
    },
    pink: {
      "400": "#EC407A",
      "500": "#E91E63",
    },
  },
};

export default colors;
