import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import ActionBar from "../components/ActionBar";
import Prose from "../components/Prose";
import { ReactComponent as ArrowBackIcon } from "bootstrap-icons/icons/arrow-left.svg";
import colors from "../colors";
import { useNavigate } from "react-router-dom";
import { TimezonelessDate } from "../date-utils";

interface ChangelogEntry {
  date: TimezonelessDate;
  description: string;
  features: string[];
}

const changelog: ChangelogEntry[] = [
  {
    date: new TimezonelessDate("2021-04-16"),
    description:
      "Tracking a lot of shipments? You can now set your display density to compact to show more shipments at one time.",
    features: [
      `New "compact" density that allows you to see more shipments at one time.`,
      "Minor performance tweaks and bug fixes.",
    ],
  },
  {
    date: new TimezonelessDate("2021-03-01"),
    description:
      "Welcome to the new Unitrack! These changes represent almost two years of updates. I hope you enjoy them!",
    features: [
      "Sync package across devices using your email address. No more weird codes to enter.",
      "You can now track DHL shipments.",
      "New iconography that feels more cross-platform.",
      "Redesigned shipment details page.",
      "Switch between light, dark, and system-default themes.",
      "Added a refresh button to force your shipments to be refreshed. Great for when you're waiting for a package to be delivered.",
      "Maskable icon support on Android.",
      "Shipments will now refresh when you return to Unitrack even if the app was still open.",
      "Tweaked shipment sorting now means the most important packages will be displayed at the top of the list.",
      "Various performance improvements. Shipments should load and sync much faster now.",
    ],
  },
];

interface EntryProps {
  entry: ChangelogEntry;
}

const Entry: React.FC<EntryProps> = ({ entry }) => {
  return (
    <Prose>
      <h2>{entry.date.toLocaleDateString()}</h2>
      {entry.description && <p>{entry.description}</p>}
      {entry.features.length > 0 && (
        <ul>
          {entry.features.map(f => {
            return <li key={f}>{f}</li>;
          })}
        </ul>
      )}
    </Prose>
  );
};

const ReleaseNotes: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Helmet>
        <title>Release Notes - Unitrack</title>
      </Helmet>
      <ActionBar
        left={
          <button onClick={() => navigate(-1)}>
            <ArrowBackIcon
              width="1em"
              height="1em"
              fill={colors.material.grey["600"]}
            />
          </button>
        }
        title="Release Notes"
      />
      <>
        {changelog.map(e => {
          return <Entry key={e.date.getTime()} entry={e} />;
        })}
      </>
    </Fragment>
  );
};

export default ReleaseNotes;
