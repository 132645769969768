import Shipment from "./Shipment";

function stripUnecessaryHTMLElements(s: string): string {
  return s.replace("<SUP>&reg;</SUP>", "");
}

function sortByName(a: Shipment, b: Shipment): number {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

function sortShipments(shipments: Shipment[]): Shipment[] {
  const hasDaysRemaining: Shipment[] = [];
  const inTransit: Shipment[] = [];
  const delivered: Shipment[] = [];

  shipments.forEach(s => {
    if (s.daysRemaining !== null) {
      hasDaysRemaining.push(s);
    } else if (s.isDelivered) {
      delivered.push(s);
    } else {
      inTransit.push(s);
    }
  });

  return [
    ...hasDaysRemaining.sort((a, b) => {
      if (a.daysRemaining! < b.daysRemaining!) {
        return -1;
      } else if (a.daysRemaining! > b.daysRemaining!) {
        return 1;
      }

      return sortByName(a, b);
    }),
    ...inTransit.sort(sortByName),
    ...delivered.sort(sortByName),
  ];
}

export { stripUnecessaryHTMLElements, sortShipments };
