import clsx from "clsx";
import React from "react";
import styles from "./Placeholder.module.css";

const Placeholder: React.FC<{ width: string; extraClassName?: string }> = ({
  width,
  extraClassName,
}) => {
  return (
    <div
      className={clsx(styles.placeholder, extraClassName)}
      style={{ width }}
    ></div>
  );
};

export default Placeholder;
