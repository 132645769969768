import { useCallback, useEffect } from "react";

interface Props {
  bindings: { [key: string]: () => void };
}

const Shortcuts: React.FC<Props> = ({ bindings }) => {
  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.srcElement &&
        ((event.srcElement as HTMLElement).tagName === "INPUT" ||
          (event.srcElement as HTMLElement).tagName === "SELECT")
      ) {
        return;
      }

      if (!bindings.hasOwnProperty(event.key)) {
        return;
      }

      bindings[event.key]();
    },
    [bindings]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleKeyEvent, false);

    return () => {
      document.removeEventListener("keyup", handleKeyEvent, false);
    };
  }, [handleKeyEvent]);

  return null;
};

export default Shortcuts;
