import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import ActionBar from "../components/ActionBar";
import Prose from "../components/Prose";
import { ReactComponent as ArrowBackIcon } from "bootstrap-icons/icons/arrow-left.svg";
import colors from "../colors";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Helmet>
        <title>Privacy Policy - Unitrack</title>
      </Helmet>
      <ActionBar
        left={
          <button title="Go back" onClick={() => navigate(-1)}>
            <ArrowBackIcon
              width="1em"
              height="1em"
              fill={colors.material.grey["600"]}
            />
          </button>
        }
        title="Privacy Policy"
      />
      <Prose>
        <h2>What information do we collect?</h2>
        <p>
          Unitrack is designed "offline-first", meaning that unless you
          specifically opt into sharing information via sync, all your shipment
          information lives and remains on your device. Should you choose to
          enable sync, the following information is then stored on Unitrack
          servers:
        </p>
        <ul>
          <li>
            <strong>Shipment information</strong>: In order to synchronize
            shipment information, we store the shipment name provided by you,
            the tracking number, the carrier, and the latest tracking response
            from the shipment carrier, which can include (but is not limited to)
            the shipment origin city, the shipment destination city, and other
            details regarding the shipment.
          </li>
          <li>
            <strong>Email address</strong>: To facilitate the synchronization of
            shipments between your devices, we require you to provide an email
            address. This email address is stored on Unitrack's database and is
            only used to facilitate device synchronization. We will not use this
            email address to contact you unless one of the following exceedingly
            rare circumstances occurs:
            <ul>
              <li>
                <strong>Security breach</strong>: If we have reason to believe
                that your email address and/or shipment data has been accessed
                by an unauthorized third-party, we may use the email address
                linked to the accessed account to notify you of such.
              </li>
              <li>
                <strong>Legally-mandated disclosure</strong>: If we are required
                by a legally-binding order to disclose your email address and/or
                shipment data, we will notify you of such unless the terms of
                said legal order prevent us from doing so.
              </li>
            </ul>
          </li>
          <li>
            <strong>Performance data</strong>: We may collect anonymous
            performance metrics from your device in order to improve Unitrack.
            This may include (but is not limited to) the time it takes your
            device to render Unitrack, the time it takes for your device to
            request shipment updates, and the time it takes for your device to
            synchronize with Unitrack's servers.
          </li>
        </ul>

        <h2>What do we use your information for?</h2>
        <p>
          Any of the information that we collect from you may be used in the
          following ways:
        </p>
        <ul>
          <li>To provide the core functionality of Unitrack.</li>
          <li>
            To improve the functionality of Unitrack by performing analysis on
            shipment tracking information and collected performance metrics.
          </li>
        </ul>

        <h2>How do we protect your information?</h2>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal information when you enter, submit, or access your
          personal information. Among other things, your browser session, as
          well as the traffic between your applications and the API, are secured
          with SSL, and any information stored in Unitrack's database is
          encrypted at rest according to{" "}
          <a href="https://cloud.google.com/security/encryption-at-rest/default-encryption">
            Google Cloud's encryption policies.
          </a>
        </p>

        <h2>What is our data retention policy?</h2>
        <ul>
          <li>
            For shipments synced to Unitrack servers, the data for the shipment
            is only deleted when you delete the shipment from one of your
            devices.
          </li>
          <li>
            Logs collected during the use of Unitrack are retained for 30 days.
          </li>
        </ul>

        <h2>Do we use cookies?</h2>
        <p>
          Unitrack does not use cookies. However, Unitrack does store
          information using localStorage, which is local to your device.
          Unitrack stores the following information in localStorage:
        </p>
        <ul>
          <li>User preferences (such as theme, display style, etc.)</li>
          <li>Authentication token (if you enable sync)</li>
        </ul>

        <h2>Do we disclose any information to outside parties?</h2>
        <p>No.</p>

        <h2>Changes to our Privacy Policy</h2>
        <p>
          If we decide to change our privacy policy, we will post those changes
          on this page.
        </p>

        <p>This document is CC-BY-SA. It was last updated March 1, 2021.</p>

        <p>
          Originally adapted from the{" "}
          <a href="https://mastodon.social/terms">
            mastodon.social privacy policy
          </a>
          .
        </p>
      </Prose>
    </Fragment>
  );
};

export default PrivacyPolicy;
