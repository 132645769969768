import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import ActionBar from "../components/ActionBar";
import Prose from "../components/Prose";
import { ReactComponent as ArrowBackIcon } from "bootstrap-icons/icons/arrow-left.svg";
import colors from "../colors";
import { useNavigate } from "react-router-dom";

const SyncDeprecation: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Helmet>
        <title>Sync Deprecation - Unitrack</title>
      </Helmet>
      <ActionBar
        left={
          <button title="Go back" onClick={() => navigate(-1)}>
            <ArrowBackIcon
              width="1em"
              height="1em"
              fill={colors.material.grey["600"]}
            />
          </button>
        }
        title="Sync Deprecation"
      />
      <Prose>
        <h2>
          Unitrack's sync functionality has been turned off as of June 1, 2024
        </h2>
        <p>
          The backend services that support Unitrack's synchronization
          capabilities were turned off on <strong>June 1, 2024</strong>. You
          will no longer be able to sync shipments between devices. Unitrack
          will continue to function, and you will still be able to add and track
          packages. The only functionality that has been removed is the ability
          to synchronize packages between devices.
        </p>
        <h3>Why did this happen?</h3>
        <p>
          Unitrack was built to fill a personal need: to easily track packages
          from multiple carriers, and to synchronize those packages across
          devices. At the time, there wasn't a good package tracking application
          for Android, and I was frustrated enough to try and solve it myself. I
          originally built Unitrack during the holiday season of 2018, and have
          since updated it here and there. The core functionality of package
          tracking is relatively stable, and doesn't cost anything to continue
          supporting.
        </p>
        <p>
          The synchronization functionality, however, cost $17.51 USD per month.
          Certainly not an outrageous amount, but still a heck of a lot more
          than $0 per month. The functionality also required accounts with three
          separate providers: Google Cloud (which hosted the primary database,
          the tracking API, the synchronization API, and the email
          authentication API), Redis Inc. (which hosted the email authentication
          database), and Mailgun (which sent emails for authentication), in
          addition to Unitrack's current hosting provider Vercel (which hosts
          getunitrack.com). As time has gone on, these providers have
          implemented changes and updates to their platform that require
          maintenance and updates on my part, and ultimately the financial and
          time investment is greater than I really wanted to provide. There's
          also the added pressure of needing to continually maintain the
          security of the synchronization database, which housed user email
          addresses. While there's not tens of thousands of email addresses
          stored in the database, it's still dozens of people who trusted me to
          keep that information secure, and I can't in good conscience do
          anything less than my best to ensure they don't end up on some spam
          list.
        </p>
        <p>
          All of this combined lead me to the decision to sunset Unitrack's
          synchronization functionality on <strong>June 1, 2024</strong>.
        </p>
        <h3>What went away?</h3>
        <p>
          On <strong>June 1, 2024</strong>, I pushed an update to the frontend
          of Unitrack that removed all synchronization functionality, deleted
          the backend database, and closed all third-party provider accounts.
          You will no longer be able to synchronize shipments between devices
          using Unitrack.
        </p>
        <h3>
          What's <em>not</em> going away?
        </h3>
        <p>
          Unitrack's core tracking functionality will not be going away any time
          soon. I still use it on a regular basis, and even have some nice UI
          improvements planned. You will still be able to add shipments to
          Unitrack and continue to get tracking updates. It's my hope that by
          removing the synchronization functionality, I can focus more on
          building a user interface that's delightful to use. The codebase for
          Unitrack is pretty creaky at this point, being over six years old, and
          I'd love to spend some time modernizing it. I've grown a lot as a
          developer over the last six years, and I want Unitrack to reflect that
          growth.
        </p>
        <h3>Are there any alternatives?</h3>
        <p>
          While I haven't come across any web apps that offer cross-device
          synchronization, there are several mobile apps that support both iOS
          and Android:
        </p>
        <ul>
          <li>
            <a href="https://shop.app/">Shop</a>
          </li>
          <li>
            <a href="https://route.com/">Route</a>
          </li>
          <li>
            <a href="https://www.aftership.com/mobile-app">AfterShip</a>
          </li>
        </ul>
        <h3>Will you change your mind?</h3>
        <p>
          Who knows what the future holds! Maybe after redesigning the UI I'll
          get excited about maintaining Unitrack again, but for the time being I
          feel pretty strongly that this is the right decision.
        </p>
      </Prose>
    </Fragment>
  );
};

export default SyncDeprecation;
