import clsx from "clsx";
import carriers, { CarrierKey } from "../../carriers";
import elevation from "../../styles/elevation.module.css";
import styles from "./TrackingNumber.module.scss";

interface TrackingNumberProps {
  carrier: CarrierKey;
}

const TrackingNumber: React.FC<TrackingNumberProps> = ({
  carrier,
  children,
}) => {
  return (
    <div
      className={clsx(styles.Container, elevation.z1)}
      style={{
        backgroundColor: carriers[carrier].backgroundColor,
        color: carriers[carrier].foregroundColor,
      }}
    >
      {children}
    </div>
  );
};

export default TrackingNumber;
