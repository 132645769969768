import React from "react";
import styles from "./CompactShipmentListItem.module.css";
import { ReactComponent as CheckCircleIcon } from "bootstrap-icons/icons/check-circle-fill.svg";
import { ReactComponent as HelpIcon } from "bootstrap-icons/icons/question-circle-fill.svg";
import { ReactComponent as ErrorIcon } from "bootstrap-icons/icons/exclamation-circle-fill.svg";
import Shipment from "../../Shipment";
import Placeholder from "../Placeholder";
import Spinner from "../../components/Spinner";
import CarrierLogo from "../../carrier-icons";
import { useDensity, useTheme } from "../../useSettings";
import clsx from "clsx";
import carriers from "../../carriers";
import elevation from "../../styles/elevation.module.css";
import { Link } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";

interface DayCountdownProps {
  shipment: Shipment;
  hasError: boolean;
  isLoading: boolean;
  density: string;
}

const DayCountdown: React.FC<DayCountdownProps> = props => {
  const { daysRemaining, isDelivered } = props.shipment;
  if (props.hasError) {
    return (
      <div className={styles.countdown}>
        <ErrorIcon />
      </div>
    );
  } else if (props.isLoading) {
    return (
      <div className={styles.countdown}>
        <Spinner size={props.density === "compact" ? 32 : 48} />
      </div>
    );
  } else if (isDelivered === true) {
    return (
      <div className={styles.countdown}>
        <CheckCircleIcon />
      </div>
    );
  } else if (daysRemaining !== null && daysRemaining >= 0) {
    return (
      <div className={styles.countdown}>
        <p className={styles.countdownDays}>{daysRemaining}</p>
        <p className={styles.countdownUnits}>
          {daysRemaining === 1 ? "day" : "days"}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.countdown}>
      <HelpIcon />
    </div>
  );
};

const FallbackShipment = () => {
  return (
    <div className={clsx(styles.container, elevation.z2)}>
      <div className={styles.details}>
        <p className={styles.name}>Whoops!</p>
        <p className={styles.statusLineOne}>
          We ran into an issue rendering this shipment.
        </p>
        <p className={styles.statusLineTwo}>
          Please refresh the page to try again.
        </p>
      </div>
      <div className={styles.countdown}>
        <ErrorIcon />
      </div>
    </div>
  );
};

interface Props {
  shipment: Shipment;
}

const CompactShipmentListItem: React.FC<Props> = ({ shipment }) => {
  const [density] = useDensity();
  const [theme] = useTheme();

  return (
    <ErrorBoundary FallbackComponent={FallbackShipment}>
      <Link
        title={shipment.name}
        to={`/shipment/${shipment.id}`}
        className={styles.link}
      >
        <div
          className={clsx(
            styles.container,
            density === "compact" && styles.containerCompact,
            theme === "light" ? styles.containerLight : elevation.z2
          )}
        >
          <div className={styles.details}>
            {density === "comfortable" && (
              <p className={clsx(styles.topLine, styles.carrierName)}>
                <CarrierLogo carrier={shipment.carrier} />
                {carriers[shipment.carrier].displayName}
              </p>
            )}
            <p className={styles.name}>{shipment.name}</p>
            {shipment.trackingInfo === null ? (
              <Placeholder width="50%" extraClassName={styles.statusLineOne} />
            ) : (
              <p className={styles.statusLineOne}>{shipment.statusLineOne}</p>
            )}
            {shipment.trackingInfo === null ? (
              <Placeholder width="30%" extraClassName={styles.statusLineTwo} />
            ) : (
              <p className={styles.statusLineTwo}>{shipment.statusLineTwo}</p>
            )}
          </div>
          <DayCountdown
            shipment={shipment}
            isLoading={
              shipment.trackingInfo === null ||
              (typeof shipment.isLoading !== "undefined" &&
                shipment.isLoading === true)
            }
            density={density}
            hasError={false}
          />
        </div>
      </Link>
    </ErrorBoundary>
  );
};

export default CompactShipmentListItem;
