import React from "react";
import carriers, { CarrierKey } from "../../carriers";
import styles from "./CarrierTrackingLink.module.css";

const Link: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  ...rest
}) => {
  return (
    <a className={styles.Link} {...rest}>
      {children}
    </a>
  );
};

function trackingLink(carrier: CarrierKey, trackingNumber: string): string {
  if (carriers.hasOwnProperty(carrier)) {
    return carriers[carrier].trackingLink(trackingNumber);
  }

  return "#";
}

interface Props {
  carrier: CarrierKey;
  trackingNumber: string;
}

const CarrierTrackingLink: React.FC<Props> = ({
  carrier,
  trackingNumber,
  children,
}) => (
  <Link
    rel="noopener noreferrer"
    target="_blank"
    href={trackingLink(carrier, trackingNumber)}
  >
    {children}
  </Link>
);

export default CarrierTrackingLink;
