import React, { useCallback, useState, type ReactNode } from "react";
import clsx from "clsx";
import { useTheme } from "../../useSettings";
import styles from "./DismissableAlert.module.css";
import elevation from "../../styles/elevation.module.css";

interface Props {
  message: ReactNode;
  isVisible: boolean;
  onDismiss: () => void;
}

const DismissableAlert: React.FC<Props> = ({
  message,
  isVisible,
  onDismiss,
}) => {
  const [show, setShow] = useState(isVisible);
  const [theme] = useTheme();

  const dismiss = useCallback(() => {
    setShow(false);
    onDismiss();
  }, [onDismiss]);

  return show ? (
    <div
      className={clsx(
        styles.container,
        theme === "light" ? styles.light : elevation.z2
      )}
    >
      <span className={styles.message}>{message}</span>
      <button onClick={dismiss}>Dismiss</button>
    </div>
  ) : null;
};

export default DismissableAlert;
