import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import ActionBar from "../../../components/ActionBar";
import Select from "../../../components/Select";
import { ReactComponent as ArrowBackIcon } from "bootstrap-icons/icons/arrow-left.svg";
import { Helmet } from "react-helmet-async";
import { useTheme, useDensity } from "../../../useSettings";
import styles from "./Settings.module.scss";

const SettingsContainer: React.FC = ({ children }) => {
  return <div className={styles.Container}>{children}</div>;
};

const ThemeContainer: React.FC = ({ children }) => {
  return <li className={styles.ThemeContainer}>{children}</li>;
};

const Settings: React.FC = () => {
  const navigate = useNavigate();

  const [density, updateStoredDensity] = useDensity();
  const [, storedTheme, updateStoredTheme] = useTheme();

  return (
    <Fragment>
      <Helmet>
        <title>Settings - Unitrack</title>
      </Helmet>
      <ActionBar
        left={
          <button title="Go back" onClick={() => navigate(-1)}>
            <ArrowBackIcon width="1em" height="1em" />
          </button>
        }
        title="Settings"
      />
      <SettingsContainer>
        <ul>
          <ThemeContainer>
            <span>Theme</span>
            <Select
              onChange={e => updateStoredTheme(e.currentTarget.value)}
              value={storedTheme ?? "system"}
            >
              <option value="system">System</option>
              <option value="light">Light</option>
              <option value="dark">Dark</option>
            </Select>
          </ThemeContainer>
          <ThemeContainer>
            <span>Display Style</span>
            <Select
              onChange={e => updateStoredDensity(e.currentTarget.value)}
              value={density}
            >
              <option value="comfortable">Comfortable</option>
              <option value="compact">Compact</option>
            </Select>
          </ThemeContainer>
          <li>
            <Link to="/sync-deprecation">Sync Deprecation</Link>
          </li>
          <li>
            <Link to="/release-notes">Release Notes</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/settings/about">About</Link>
          </li>
        </ul>
      </SettingsContainer>
    </Fragment>
  );
};

export default Settings;
